<template>
    <div class="shilut-deals-db">
        <div class="shilut-deals-db-header">
            <h1>ניהול מבצעים</h1>
            {{ promos.length }} {{ items.length }}
        </div>
        <div v-if="promos.length > 0 && items.length > 0" class="shilut-deals-db-tools">
            <el-input style="width:30%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
            <el-button @click="handle_reset_filters" style="margin:0 5px;" type="primary">נקה מסננים</el-button>
            <el-button @click="filters.current_deals_month = !filters.current_deals_month" style="margin:0 5px;"
                :type="filters.current_deals_month ? 'success' : null">מבצעי החודש</el-button>
            <!-- <el-button @click="handle_filter_by_products" style="margin:0 5px;" type="primary">הראה מבצעים בלי מוצרים</el-button> -->
            <el-button @click="handle_move_to_signs_catalog" style="margin:0 5px;" type="primary">העברה לשילוט
                קטלוגי</el-button>
            <el-button v-if="false" @click="handle_export_to_excel" style="margin:0 5px;" type="success">ייצא
                לאקסל</el-button>
            <el-button @click="handle_export_deals_items_to_excel" style="margin:0 5px;" type="success">ייצא מוצרים
                שבמבצעים</el-button>
            <el-button @click="general_push_to('ShilutYedion'); handle_clean_checks_boxes()" style="margin:0 5px;"
                type="warning">ידיעון</el-button>
            <div v-if="promos.length > 0 && items.length > 0" @click="items = []; promos = [];" class="back-btn">
                <i class="material-icons">arrow_back</i>
            </div>
        </div>
        <div v-if="promos.length > 0 && items.length > 0" class="shilut-deals-db-filters">
            <el-button @click="handle_clean_checks_boxes" class="slide-in-right" v-if="selected_deals.length > 0"
                style="margin-right:5px;" type="danger">נקה בחירה</el-button>
            <el-button @click="handle_push_to_signs" class="slide-in-right" v-if="selected_deals.length > 0"
                style="margin-right:5px;" type="warning">הנפק שילוט</el-button>
            <span>מתאריך:</span>
            <el-input v-model="filters.from_date_string" style="width:150px;" type="date" placeholder="Please input" />
            <span>עד תאריך:</span>
            <el-input v-model="filters.to_date_string" style="width:150px;" type="date" placeholder="Please input" />
            <el-button @click="handle_filter_by_dates" type="primary">סנן</el-button>
            <el-select filterable v-model="selected_category" class="m-2" placeholder="בחר מחלקה" size="large">
                <el-option v-for="cat in categories" :key="cat" :value="cat" />
            </el-select>
            <el-select filterable v-model="selected_branche" class="m-2" placeholder="בחר סניף" size="large">
                <el-option v-for="branche in branches" :key="branche" :value="branche" />
            </el-select>
        </div>
        <div class="shilut-deals-db-content">
            <div class="reload-options" v-if="promos.length == 0 && items.length == 0">
                <!-- <el-button @click="handle_reload_previos_data"
                    style="margin:0; height:100px; max-width:300px; width:90%;" size="large" type="primary">טען נתונים
                    קודמים</el-button>
                <br> -->
                <el-button @click="handle_reload_new_data"
                    style="margin:0 10px; height:100px; max-width:300px; width:90%;" type="success">רענן נתונים
                    חדשים</el-button>
                <br>
                <el-button v-if="role >= 99" @click="ShowSuppliersManageModal = true"
                    style="margin:0 10px; height:100px; max-width:300px; width:90%;" type="warning">ניהול
                    ספקים</el-button>
            </div>
            <table style="table-layout: fixed; width: 100%;" v-if="promos.length > 0 && items.length > 0">
                <tr>
                    <th style="width:50px;"></th>
                    <th style="cursor:pointer;" @click="handleSortTable('קוד', !selectedSorted.order)">קוד</th>
                    <th style="cursor:pointer;" @click="handleSortTable('ספק', !selectedSorted.order)">ספק</th>
                    <th style="cursor:pointer;" @click="handleSortTable('שם', !selectedSorted.order)">שם</th>
                    <th style="cursor:pointer;" @click="handleSortTable('סניפים', !selectedSorted.order)">סניפים</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מתאריך', !selectedSorted.order)">מתאריך</th>
                    <th style="cursor:pointer;" @click="handleSortTable('עד תאריך', !selectedSorted.order)">עד תאריך
                    </th>
                </tr>
                <h1 v-if="slice_list.length == 0">לא קיימים רשומות</h1>
                <template v-for="(deal) in slice_list" :key="deal.promoCode">
                    <tr>
                        <td>
                            <input type="checkbox" :checked="isDealSelected(deal)" :value="deal.promoCode"
                                @change="toggleDealSelection(deal)">
                        </td>
                        <td @click="stam(deal)">{{ deal.promoCode }}</td>
                        <td>{{ deal.supplier ? deal.supplier : 'לא צויין ספק' }}</td>
                        <td>{{ deal.RemarkForPrint ? deal.RemarkForPrint : 'לא צויין' }}</td>
                        <!-- <td style="word-wrap: break-word;">{{deal.Stores[0]?deal.Stores.join(','):'כולם'}}</td> -->
                        <td style="word-wrap: break-word;">כולם</td>
                        <td>{{ deal.products?.length > 0 ? deal.products[0].Department : 'לא צויין' }}</td>
                        <td>{{ new Date(deal.FromDate).toLocaleDateString('he') }}</td>
                        <td>{{ new Date(deal.ToDate).toLocaleDateString('he') }}</td>
                    </tr>
                </template>
            </table>
        </div>
        <div v-if="promos.length > 0 && items.length > 0" class="shilut-deals-db-pagination">
            <el-pagination v-model:currentPage="pagination.current_page" background
                :page-size="pagination.number_per_page" layout="prev,pager,next" :total="sorted_list.length" />
        </div>
        <SuppliersManageModal v-if="ShowSuppliersManageModal" @close="ShowSuppliersManageModal = false" />

        <LoadingScreen v-if="loader" />
        <!-- <Tachzuka /> -->

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { role } from '../../../../../Methods/Utils'
import Tachzuka from './Tachzuka.vue'
import {
    items_and_promos_data_from_catch,
    items_and_promos_data_from_API,
    items,
    promos,
    categories,
    branches,
} from './Scripts/script'



import { selected_deals, is_catalog_signs, printer_amount, is_deleted } from './Scripts/store'

import { slide_pop_error } from '../../../../../Methods/Msgs'
import { computed, defineAsyncComponent } from '@vue/runtime-core'
import Checkbox from 'primevue/checkbox';
import router from '../../../../../router'
import XLSX from "xlsx";
export default {
    emits: ['back'],
    components: {
        Checkbox,
        CreateNewDeal: defineAsyncComponent(() => import('./CreateNewDeal.vue')),
        ExcelDataPreview: defineAsyncComponent(() => import('./ExcelDataPreview.vue')),
        LoadingScreen: defineAsyncComponent(() => import('./LoadingScreen.vue')),
        SuppliersManageModal: defineAsyncComponent(() => import('../Suppliers/SuppliersManageModal.vue')),
        Tachzuka,
    },
    setup() {
        const isDealSelected = (deal) => {
            return selected_deals.value.some(i => i.promoCode === deal.promoCode);
        };
        const toggleDealSelection = (deal) => {
            const index = selected_deals.value.findIndex(i => i.promoCode === deal.promoCode);
            if (index !== -1) {
                selected_deals.value = selected_deals.value.filter(({ promoCode }) => promoCode != deal.promoCode);

            } else {
                selected_deals.value.push(deal);
            }
        }
        const stam = (deal) => {
            console.log(deal);
        }

        const ShowSuppliersManageModal = ref(false)

        const selected_category = ref('')
        const selected_branche = ref('')

        const filters = ref({
            from_date_string: '',
            to_date_string: '',
            from_date: false,
            to_date: false,
            products: false,
            current_deals_month: false
        })
        const loader = ref(false)
        const selected_excel_data = ref([])
        const upload = ref()
        const selected_deal_to_edit = ref(null)
        const show_create_deal = ref(false)
        const search = ref('')
        const pagination = ref({
            current_page: 1,
            number_per_page: 50,
        })

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const current_month = ref(computed(() => {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            return currentMonth
        }))

        const curent_year = ref(computed(() => {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            return currentYear;
        }))

        function getCurrentYear(isoDate) {
            const currentDate = new Date(isoDate);
            const currentYear = currentDate.getFullYear();
            return currentYear;
        }

        function getCurrentMonth(isoDate) {
            const currentDate = new Date(isoDate);
            const currentMonth = currentDate.getMonth() + 1;
            return currentMonth;
        }

        function stripTimeFromDate(dateString) {
            const date = new Date(dateString);
            date.setHours(0, 0, 0, 0);
            return date;
        }

        const handle_export_to_excel = () => {
            try {
                if (!items.value || !promos.value) {
                    throw new Error('לא ניתן לייצא לאקסל עד שכל הנתונים יטענו!')
                }


                console.log(items.value);

                let workbook = XLSX.utils.book_new();
                let workSheetData = []
                workSheetData.push(['קוד', 'ספק', 'שם', 'סניפים', 'מחלקה', 'מתאריך', 'עד תאריך', 'סוג מבצע'])
                promos.value.forEach(promo => {
                    workSheetData.push(
                        [
                            promo.promoCode,
                            promo.supplier ? promo.supplier : 'לא צויין',
                            promo.RemarkForPrint,
                            promo.Stores[0] ? promo.Stores.join(',') : 'כולם',
                            promo.products?.length > 0 ? promo.products[0].Department : 'לא צויין',
                            new Date(promo.FromDate).toLocaleDateString('he'),
                            new Date(promo.ToDate).toLocaleDateString('he'),
                            promo.MivzaTypeNm
                        ]
                    )
                })
                const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
                workbook.Workbook = {};
                workbook.Workbook.Views = [];
                workbook.Workbook.Views[0] = {};
                workbook.Workbook.Views[0].RTL = true;
                XLSX.utils.book_append_sheet(workbook, workSheet, "פריטים חריגים");
                XLSX.writeFile(workbook, "מבצעים.xlsx");

                /* ================================================== */

                let workbookItems = XLSX.utils.book_new();
                let workSheetDataItems = []
                workSheetDataItems.push(['ברקוד', 'קוד', 'מזהה', 'שם', 'קבוצה', 'קוד קבוצה', 'מחלקה', 'קוד מחלקה', 'תאריך ארכיון', 'BlockPurchaseDate', 'BlockSalesDate', 'weight', 'amount', 'מחיר'])
                items.value.forEach(item => {
                    workSheetDataItems.push(
                        [
                            item.barcode,
                            item.code,
                            item.id,
                            item.Name,
                            item.Group,
                            item.GroupCode,
                            item.Department,
                            item.DepartmentCode,
                            item.ArchiveDate,
                            item.BlockPurchaseDate,
                            item.BlockSalesDate,
                            item.weight,
                            item.amount ? item.amount : 'לא צויין',
                            item.price
                        ]
                    )
                })
                const workSheetItems = XLSX.utils.aoa_to_sheet(workSheetDataItems)
                workbookItems.Workbook = {};
                workbookItems.Workbook.Views = [];
                workbookItems.Workbook.Views[0] = {};
                workbookItems.Workbook.Views[0].RTL = true;
                XLSX.utils.book_append_sheet(workbookItems, workSheetItems, "פריטים חריגים");
                XLSX.writeFile(workbookItems, "פריטים.xlsx");

            } catch (err) {
                slide_pop_error(err.message)
            }
        }

        const handle_export_deals_items_to_excel = () => {
            const items = []
            const is_duplicate = new Set()
            for (const promo of promos.value) {
                if (promo.products.length > 0) {
                    for (const item of promo.products) {
                        if (!is_duplicate.has(item.barcode)) {
                            items.push({
                                barcode: item.barcode,
                                ind: 1
                            })
                            is_duplicate.add(item.barcode)
                        }
                    }
                }
            }

            let workbook = XLSX.utils.book_new();
            let workSheetData = []
            workSheetData.push(['ברקוד', 'אינדקטור'])
            items.forEach(item => {
                workSheetData.push(
                    [
                        item.barcode,
                        item.ind
                    ]
                )
            })
            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "פריטים חריגים");
            XLSX.writeFile(workbook, "פריטים השייכים למבצעים קיימים.xlsx");

        }

        const slice_list = ref(computed(() => {
            return sorted_list.value.slice((pagination.value.current_page - 1) * pagination.value.number_per_page,
                ((pagination.value.current_page - 1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))

        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "") return filter_by_branches.value;
            if (selectedSorted.value.name == "קוד") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return Number(a.promoCode) > Number(b.promoCode)
                            ? 1
                            : Number(b.promoCode) > Number(a.promoCode)
                                ? -1
                                : 0;
                    }
                    return Number(a.promoCode) > Number(b.promoCode)
                        ? -1
                        : Number(b.promoCode) > Number(a.promoCode)
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "ספק") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return a?.supplier > b?.supplier
                            ? 1
                            : b?.supplier > a?.supplier
                                ? -1
                                : 0;
                    }
                    return a?.supplier > b?.supplier
                        ? -1
                        : b?.supplier > a?.supplier
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "שם") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return a?.RemarkForPrint > b?.RemarkForPrint
                            ? 1
                            : b?.RemarkForPrint > a?.RemarkForPrint
                                ? -1
                                : 0;
                    }
                    return a?.RemarkForPrint > b?.RemarkForPrint
                        ? -1
                        : b?.RemarkForPrint > a?.RemarkForPrint
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "סניפים") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return a?.Stores.length > b?.Stores.length
                            ? 1
                            : b?.Stores.length > a?.Stores.length
                                ? -1
                                : 0;
                    }
                    return a?.Stores.length > b?.Stores.length
                        ? -1
                        : b?.Stores.length > a?.Stores.length
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "מתאריך") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return new Date(a?.FromDate) > new Date(b?.FromDate)
                            ? 1
                            : new Date(b?.FromDate) > new Date(a?.FromDate)
                                ? -1
                                : 0;
                    }
                    return new Date(a?.FromDate) > new Date(b?.FromDate)
                        ? -1
                        : new Date(b?.FromDate) > new Date(a?.FromDate)
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "עד תאריך") {
                return filter_by_branches.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return new Date(a?.ToDate) > new Date(b?.ToDate)
                            ? 1
                            : new Date(b?.ToDate) > new Date(a?.ToDate)
                                ? -1
                                : 0;
                    }
                    return new Date(a?.ToDate) > new Date(b?.ToDate)
                        ? -1
                        : new Date(b?.ToDate) > new Date(a?.ToDate)
                            ? 1
                            : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return filter_by_branches.value.sort((a, b) => {
                    const aProducts = a.products || [];
                    const bProducts = b.products || [];
                    if (aProducts.length === 0 && bProducts.length === 0) {
                        return 0;
                    } else if (aProducts.length === 0) {
                        return 1;
                    } else if (bProducts.length === 0) {
                        return -1;
                    } else {
                        if (selectedSorted.value.order) {
                            return (aProducts[0].Department > bProducts[0].Department)
                                ? 1
                                : bProducts[0].Department > aProducts[0].Department
                                    ? -1
                                    : 0;
                        }
                        return (aProducts[0].Department > bProducts[0].Department)
                            ? -1
                            : bProducts[0].Department > aProducts[0].Department
                                ? 1
                                : 0;
                    }
                });
            }
        }));

        const filter_by_search = ref(computed(() => {
            if (!search.value) return promos.value
            return promos.value.filter(deal => {
                if (deal.products.length > 0 && deal.products.map((product) => product.barcode).join().includes(search.value)) return deal
                if (deal.promoCode?.includes(search.value)) return deal
                if (deal.supplier?.includes(search.value)) return deal
                if (deal.RemarkForPrint?.includes(search.value)) return deal
                if (deal.Stores?.includes(search.value)) return deal
                if (new Date(deal.FromDate).toLocaleDateString('he').includes(search.value)) return deal
                if (new Date(deal.ToDate).toLocaleDateString('he').includes(search.value)) return deal
            })
        }))

        const filter_by_categories = ref(computed(() => {
            if (!selected_category.value || selected_category.value == 'הכל') return filter_by_current_month.value
            return filter_by_current_month.value.filter(deal => {
                if ((deal?.products?.length > 0) && (deal?.products[0]?.Department == selected_category.value)) {
                    return deal
                }
            })
        }))

        const filter_by_branches = ref(computed(() => {
            if (!selected_branche.value || selected_branche.value == 'הכל') return filter_by_categories.value
            return filter_by_categories.value.filter(deal => {
                if (deal?.Stores?.includes(selected_branche.value) || (deal?.Stores?.length == 1 && !deal?.Stores[0])) return deal
            })
        }))

        const filter_by_current_month = ref(computed(() => {
            if (filters.value.current_deals_month) {
                return filter_by_dates.value.filter(deal => {
                    if (getCurrentMonth(deal.FromDate) == current_month.value && getCurrentYear(deal.FromDate) == curent_year.value) return deal
                })
            }
            return filter_by_dates.value
        }))

        const filter_by_dates = ref(computed(() => {
            if (filters.value.products) {
                return filter_by_search.value.filter(deal => {
                    if (filters.value.products && deal.products.length == 0) return deal
                })
            }
            if (!filters.value.from_date && !filters.value.to_date) return filter_by_search.value
            return filter_by_search.value.filter(deal => {
                if (filters.value.products && deal.products.length > 0) return deal
                if (filters.value.from_date && filters.value.to_date) {
                    if (stripTimeFromDate(deal.FromDate) >= stripTimeFromDate(filters.value.from_date_string) && stripTimeFromDate(deal.ToDate) <= stripTimeFromDate(filters.value.to_date_string)) {
                        return deal
                    }
                }
                else if (filters.value.from_date && !filters.value.to_date) {
                    if (stripTimeFromDate(deal.FromDate) >= stripTimeFromDate(filters.value.from_date_string))
                        return deal
                }
                else if (filters.value.to_date && !filters.value.from_date) {
                    if (stripTimeFromDate(deal.ToDate) <= stripTimeFromDate(filters.value.to_date_string)) {
                        return deal
                    }
                }
            })
        }))

        const handle_clean_checks_boxes = () => {
            selected_deals.value = []
            printer_amount.value = []
            is_deleted.value = false
        }

        const handle_move_to_signs_catalog = () => {
            is_catalog_signs.value = true
            selected_deals.value = []
            router.push({ name: 'Shilut' })
        }

        const handle_push_to_signs = () => {
            is_catalog_signs.value = false
            router.push({ name: 'Shilut' })
        }

        const handle_filter_by_products = () => {
            filters.value.products = true
        }

        const handle_reset_filters = () => {
            filters.value = {
                from_date_string: '',
                to_date_string: '',
                from_date: false,
                to_date: false,
                products: false,
                current_deals_month: false
            }
            search.value = ''
            selected_category.value = ''
        }

        const handle_filter_by_dates = () => {
            if (filters.value.from_date_string) {
                filters.value.from_date = true
            } else {
                filters.value.from_date = false
            }
            if (filters.value.to_date_string) {
                filters.value.to_date = true
            } else {
                filters.value.to_date = false
            }
        }

        const handle_reload_new_data = async () => {
            try {
                loader.value = true
                await items_and_promos_data_from_API()
                loader.value = false
            } catch (err) {
                slide_pop_error(err.message)
            }
        }

        const handle_reload_previos_data = () => {
            try {
                loader.value = true
                setTimeout(async () => {
                    try {
                        await items_and_promos_data_from_catch()
                        loader.value = false
                    } catch (err) {
                        loader.value = false
                        slide_pop_error(err.message)
                    }
                }, 500);
            } catch (err) {
                loader.value = false
                slide_pop_error(err.message)
            }
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };

        const general_push_to = (name) => {
            router.push({ name })
        }





        return {
            stam,
            isDealSelected,
            toggleDealSelection,
            handleSortTable,
            handle_reload_previos_data,
            handle_reload_new_data,
            items_and_promos_data_from_API,
            handle_filter_by_dates,
            handle_reset_filters,
            handle_filter_by_products,
            handle_push_to_signs,
            handle_clean_checks_boxes,
            handle_move_to_signs_catalog,
            handle_export_to_excel,
            handle_export_deals_items_to_excel,
            general_push_to,
            search,
            show_create_deal,
            selected_deal_to_edit,
            filter_by_search,
            selectedSorted,
            sorted_list,
            slice_list,
            pagination,
            upload,
            selected_excel_data,
            promos,
            items,
            loader,
            filters,
            filter_by_dates,
            selected_deals,
            filter_by_current_month,
            categories,
            selected_category,
            branches,
            selected_branche,
            ShowSuppliersManageModal,
            role,
        }
    }
}
</script>

<style scoped>
.shilut-deals-db {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 5px;
    color: #fff;
    overflow: hidden;
}

.shilut-deals-db-header {
    position: relative;
    width: 100%;
    height: 50px;
    background: var(--secondary);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shilut-deals-db-tools,
.shilut-deals-db-filters {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.shilut-deals-db-filters>* {
    margin: 0 5px;
}

@media only screen and (max-width: 600px) {

    .shilut-deals-db-tools>*,
    .shilut-deals-db-filters>* {
        flex-shrink: 0;
    }

    .shilut-deals-db-tools,
    .shilut-deals-db-filters {
        overflow-y: auto;
    }
}

.shilut-deals-db-content {
    width: 100%;
    height: calc(100% - 200px);
    overflow-y: auto;
}

.reload-options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.shilut-deals-db-pagination {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

table {
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 2px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:nth-child(odd) {
    background-color: #e7e6e6;
}

table tr:hover {
    background-color: #ddd;
}

table td {
    text-align: center;
    color: var(--main);
    font-size: 18px;
}

table th {
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: var(--purple);
    color: white;
    position: sticky;
    top: 0;
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    table th {
        position: static;
    }
}

.icon {
    cursor: pointer;
}

.delete-icon {
    color: var(--danger);
    margin: 0 5px;
}

.edit-icon {
    color: var(--warning);
}

.back-btn {
    margin-right: auto;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--danger);
    border-radius: 50%;
    cursor: pointer;
}


::v-deep(.el-pagination.is-background .btn-next i) {
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

::v-deep(.el-pagination.is-background .btn-prev i) {
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background: var(--success);
    color: #fff;

}

.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
</style>